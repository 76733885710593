import { graphql, Link, PageProps } from 'gatsby';
import React from 'react';

import { Layout, LayoutBrandData } from '../components/layout';
import { SEO } from '../components/seo';

interface BrandNode extends LayoutBrandData {
  slug: string;
}

type QueryData = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
  allBrandsJson: {
    edges: Array<{
      node: BrandNode;
    }>;
  };
  defaultBrand: BrandNode;
};

const IndexPage = ({ data }: PageProps<QueryData>) => {
  const brandEdges = data.allBrandsJson.edges;

  return (
    <Layout brand={data.defaultBrand}>
      <SEO title="Home" />
      <h1 className="heading2">Available brands</h1>
      <ul>
        {brandEdges.map(({ node }) => {
          return (
            <li className="body" key={node.slug}>
              <Link to={`/${node.slug}`}>{node.name}</Link>
            </li>
          );
        })}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query IndexPageBrands {
    site {
      siteMetadata {
        title
      }
    }
    allBrandsJson {
      edges {
        node {
          ...LayoutBrandFragment
          slug
        }
      }
    }
    defaultBrand: brandsJson(id: { eq: "corgi" }) {
      ...LayoutBrandFragment
      slug
    }
  }
`;

export default IndexPage;
